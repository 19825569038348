.userupload {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  
    .usern {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
  
    .user-profile {
      display: flex;
      align-items: center;
  
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }
  
      p {
        font-size: 18px;
        font-weight: bold;
        color: #000;
      }
    }
  
    .video-upload {
      width: 800px;
      
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
      h1 {
        font-size: 24px;
        margin-bottom: 20px;
        color: #000;
      }
  
      input[type='file'] {
        margin-bottom: 20px;
      }
  
      input[type='text'],
      textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
      }
  
      textarea {
        resize: vertical;
        height: 100px;
      }
  
      button {
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #0056b3;
        }
  
        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
  
      video {
        width: 100%;
        border-radius: 5px;
      }
    }
    /* Media Query for screens smaller than 768px */
  @media (max-width: 768px) {
    .video-upload {
      width: calc(100% - 40px); /* Adjusted width to occupy full width with some padding */
      padding: 10px; /* Reduced padding for smaller screens */
    }
  }
  }