.login {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  ), url("/image/battle-of-adwa-ethiopias-victory.jpg");
  background-size: cover;
  position: relative;

  .top {
    .wrapper {
      padding: 20px 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: 100px;
      }
    }
  }
    .loginButton{
      height: 40px;
      border-radius: 5px;
      background-color: #00916E;
      color: white;
      border: none;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      width: 100px;
      margin-left: 50px;
    }
  .container {
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left: 10px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    form {
      h4 {

        
        text-align: center;
        font-size: 35px;
        text-decoration: underline;
        font-family: 'Times New Roman', Times, serif;
        color: antiquewhite;
      }

      width: 300px; /* Adjusted width for better alignment */
      padding: 30px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      input {
        height: 40px;
        margin-bottom: 15px; /* Added margin between input fields */
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        padding-left: 10px;
        font-family: 'Times New Roman', Times, serif;
          width: 280px;
        &::placeholder {
          color: rgb(0, 0, 0);
        }
      }
      button {
        height: 40px;
        border-radius: 5px;
        background-color: #00916E;
        color: white;
        border: none;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        width: 200px;
        margin-left: 50px;
      }
      
      span {
         margin-top: 25px;
        text-align: center;
        color: lightgray;

        b {
          color: white;
        }
      }
      small{
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}
/* Media Query for screens smaller than 768px */
@media (max-width: 768px) {
  .login .container form {
    width: 250px; /* Adjusted width for smaller screens */
  }

  .login .loginButton {
    width: 70px; /* Adjusted width for smaller screens */
    margin-left: 10px; /* Adjusted margin for smaller screens */
  }
}