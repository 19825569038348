.pending-users-list {
  margin-top: 20px;
  margin-left: 280px;
  width: calc(100% - 230px);
  max-width: 800px;

  h2 {
    font-size: 1.6rem;
    margin-bottom: 10px;
    color: #333;
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      background-color: #fff;
      border-radius: 8px;
      margin-bottom: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      .user-details {
        padding: 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;

        h3 {
          margin: 0;
          font-size: 1.2rem;
          color: #333;
        }

        p {
          margin: 3px 0 0;
          color: #666;
          font-size: 0.9rem;
        }

        .user-actions {
          display: flex;
          align-items: center;

          button {
            padding: 6px 10px;
            margin-left: 8px;
            cursor: pointer;
            background-color: transparent;
            color: #007bff;
            border: 1px solid #007bff;
            border-radius: 4px;
            transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

            &:hover {
              background-color: #007bff;
              color: #fff;
              border-color: #007bff;
            }

            &.reject-button {
              background-color: transparent;
              border-color: #dc3545;
              color: #dc3545;

              &:hover {
                background-color: #dc3545;
                color: #fff;
                border-color: #dc3545;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-left: 20px; /* Adjusted margin for smaller screens */
    margin-right: 20px; /* Adjusted margin for smaller screens */
    width: calc(100% - 40px); /* Adjusted width for smaller screens */
  }
}