.sidebar {
  width: 240px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #00a9a5;
  color: #ffffff;
  transition: width 0.3s ease;
  overflow-y: auto;

  .sidebar-header {
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #ffffff;
  }

  .sidebar-menu {
    list-style-type: none;
    padding-left: 0;

    li {
      a {
        display: flex;
        align-items: center;
        color: #ffffff;
        text-decoration: none;
        padding: 12px 20px;
        transition: background-color 0.3s;

        &:hover {
          background-color: #092327;
        }

        &.active-link {
          background-color: #007bff;
        }

        .icon {
          margin-right: 10px;
        }

        span {
          margin-left: 5px;
        }
      }

      .submenu-items {
        display: none;
        padding-left: 20px;

        li {
          a {
            padding: 8px 16px;
          }
        }
      }
    }

    .collapsed .submenu-items {
      display: block;
    }
  }

  .submenu-header {
    cursor: pointer;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      margin-right: 10px;
    }
  }

  &.collapsed {
    width: 80px;

    .submenu-header {
      justify-content: center;
    }

    .icon {
      margin-right: 0;
    }

    .sidebar-menu {
      li {
        a {
          justify-content: center;

          span {
            display: none;
          }
        }

        .submenu-items {
          padding-left: 0;

          li {
            a {
              justify-content: center;
            }
          }
        }
      }
    }
  }
  .sidebar-footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0 20px;
  }

  .footer-link {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    padding: 12px 0;
    transition: background-color 0.3s;

    &:hover {
      background-color: #092327;
    }

    .footer-icon {
      margin-right: 10px;
    }

    span {
      margin-left: 5px;
    }
  }
}
/* Media Queries */
@media only screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
}