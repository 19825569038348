.navbar {
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 14px;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color:#00a9a5;
  transition: background-color 0.3s ease;

  &.scrolled {
    background-color:#092327;
    color: #ebf2fa;
  }

  .container {
    padding: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        margin-right: 20px;
        transition: transform 0.3s ease;
      }
    }

    .center {
     
      color: #dfecee;
      margin-left: 200px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      &.scrolled {
        background-color:#00a9a5;
        color: #00a9a5;
      }
      .links {
        display: flex;
        align-items: center;

        span {
          margin-right: 20px;
          cursor: pointer;
          transition: color 0.3s ease; /* Added transition for text color change */
          position: relative;

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -3px;
            left: 0;
            background-color: transparent;
            transition: background-color 0.3s ease;
          }

          &:hover {
            color: #dfecee; /* Change text color on hover */
            &:after {
              background-color: #092327; /* Change underline color on hover */
            }
            transform: scale(1.1); /* Scale up on hover */
            opacity: 0.8; /* Reduce opacity on hover */
          }
        }
      }
    }

    .right {
      margin-left: 700px;
      display: flex;
      align-items: center;

      .notification-icon,
      .account-icon {
        color: black;
        font-size: 30px;
        cursor: pointer;
        margin-left: 20px;
        transition: transform 0.3s ease;
      }
    }
  }
}

/* Animation for scrolling */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar.scrolled .container {
  animation: fadeInDown 0.5s ease;
}

.topbar {
  width: 100%;
  height: 50px;
  background-color: #003459;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 40px;
  color: wheat;
  cursor: pointer;
}

.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer {
  
  padding-left: 10px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: wheat
}

.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}
/* Media Queries */
@media only screen and (min-width: 768px) {
  .navbar .container {
    padding: 0px 50px; /* Restore padding for larger screens */
  }

  .navbar .left img {
    width: 50px; /* Restore image size for larger screens */
    margin-right: 20px; /* Restore margin for larger screens */
  }

  .navbar .center {
    margin-left: 200px; /* Adjusted margin for larger screens */
  }

  .navbar .right {
    display: flex; /* Show the right section for larger screens */
    margin-left: auto; /* Align right section to the right */
    align-items: center;
  }
}