.topRatedVideosList {
  margin-top: 20px;
  margin-left: 230px;
  width: calc(100% - 230px);
  max-width: 800px;

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }

  .video-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;

    .video-card {
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: translateY(-5px);
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-bottom: 2px solid #333;
      }

      .video-info {
        padding: 20px;

        h3 {
          margin: 0;
          font-size: 20px;
          color: #333;
        }

        p {
          margin: 10px 0;
          font-size: 16px;
          color: #555;
        }

        .stats {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #777;
        }

        .actions {
          margin-top: 15px;

          button {
            padding: 8px 12px;
            margin-right: 10px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &.watch-btn {
              background-color: #3498db;
              color: #fff;
            }

            &.like-btn {
              background-color: #2ecc71;
              color: #fff;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  /* Media Query for screens smaller than 768px */
  @media (max-width: 768px) {
    margin-left: 0; /* Adjusted margin-left for smaller screens */
    width: 100%; /* Adjusted width to fill the viewport width on smaller screens */

    .video-list {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjusted grid layout for smaller screens */
    }
  }

}
