.userA {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .controls {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    input[type="text"] {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 60%;
    }

    select {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 35%;
    }
  }

  .accounts {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;

    .account {
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: white;
      border-radius: 8px;
      margin-bottom: 15px;

      .profilePicContainer {
        flex-shrink: 0;
        margin-right: 15px;

        .profilePic {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }

      .accountInfo {
        p {
          margin: 0;
          font-size: 16px;
          color: #006400;
        }

        .disable, .approve, .terminte {
          margin-top: 10px;
          margin-left: 5px;
          padding: 8px 15px;
          border: none;
          border-radius: 5px;
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: bold;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          }
        }

        .disable {
          background-color: #ff0000;
          &:hover {
            background-color: #cc0000;
          }
        }

        .approve {
          background-color: #006400;
          &:hover {
            background-color: #005600;
          }
        }

        .terminte {
          background-color: gray;
          color: #000000;
          &:hover {
            background-color: #666666;
          }
        }
      }
    }
  }

  /* Media Query for screens smaller than 768px */
  @media (max-width: 768px) {
    .controls {
      flex-direction: column;
      align-items: stretch;

      input[type="text"], select {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .accounts {
      padding: 0 20px; /* Adjusted padding for smaller screens */

      .account {
        .profilePicContainer {
          margin-right: 0; /* Removed margin-right for smaller screens */
          margin-bottom: 0; /* Removed margin-bottom for smaller screens */
        }

        .accountInfo {
          button {
            width: 100%; /* Make buttons full width on smaller screens */
          }
        }
      }
    }
  }
}
