// SearchPage.scss

.search-page-container {
  margin: 20px;
  margin-left: 320px;
  margin-top: 100px;
  padding: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333333;
  }

  .search-form {
    margin-bottom: 30px;

    input[type="text"] {
      width: 100%;
      padding: 16px;
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      font-size: 18px;
      outline: none;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #007bff;
      }
    }

    button {
      padding: 16px 28px;
      border: none;
      border-radius: 12px;
      background-color: #007bff;
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;

      &:hover {
        background-color: #0056b3;
        transform: translateY(-3px);
      }
    }
  }

  .search-results {
    padding: 20px;
    border-radius: 12px;
    background-color: #f5f5f5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 32px;
      margin-bottom: 15px;
      color: #333333;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 10px;

        a {
          text-decoration: none;
          color: #007bff;
          font-size: 18px;
          transition: color 0.3s ease;

          &:hover {
            color: #0056b3;
          }
        }
      }
    }

    p {
      font-size: 18px;
      color: #777777;
      margin-top: 10px;
    }
  }
}
