.lateVid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  background-color: #f0f0f0;
  margin-left: 300px;

  .lateVidContent {
    max-width: 1000px;
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 28px;
      color: #333;
      text-transform: uppercase;
    }

    .videoContainer {
      position: relative;
      margin-bottom: 50px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;

      video {
        width: 400px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }

      .videoInfo {
        margin-top: 15px;
        color: #333;

        p {
          margin: 5px 0;
        }
      }

      .actionButtons {
        position: absolute;
        bottom: 10px;
        right: 10px;

        button {
          display: flex;
          align-items: center;
          gap: 8px;
          text-transform: uppercase;
          font-weight: bold;
          padding: 8px 16px;
          border-radius: 5px;
          font-size: 14px;
          transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
          cursor: pointer;

          .icon {
            font-size: 18px;
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          }
        }

        .app {
          background-color: #007bff;
          color: #fff;

          &:hover {
            background-color: #0056b3;
          }
        }

        .dic {
          background-color: #ff0000;
          color: #fff;

          &:hover {
            background-color: #ff5757;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .lateVidContent {
      .videoContainer {
        padding: 15px;
        margin-bottom: 20px;

        .actionButtons {
          button {
            padding: 6px 12px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
/* Media Query for screens smaller than 768px */
@media (max-width: 768px) {
  .lateVidContent .videoContainer {
    padding: 15px;
    margin-bottom: 20px;
  }

  .lateVidContent .actionButtons button {
    padding: 6px 12px;
    font-size: 12px;
  }
}