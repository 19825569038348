// Define colors
$primaryColor: #3f51b5; // Primary color
$secondaryColor: #f50057; // Secondary color
$bgColor: #f9f9f9; // Background color
$errorColor: #f44336; // Error color
$successColor: #4caf50; // Success color
$inputBorderColor: #ccc; // Border color for input fields

.home {
  display: flex;
  justify-content: center;
  margin-top: 100px;

  .homeContainer {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    background-color: $bgColor;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h2 {
      font-size: 28px;
      color: $successColor;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 20px;
    }

    .accountForm {
      width: 100%;

      .formGroup {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        label {
          font-weight: bold;
          margin-bottom: 8px;
          color: $successColor;
          text-transform: uppercase;
          font-size: 14px;
        }

        input,
        select {
          padding: 12px;
          border: 1px solid $inputBorderColor;
          border-radius: 8px;
          margin-bottom: 10px;
          transition: border-color 0.3s ease-in-out;
          font-size: 16px;

          &:focus {
            border-color: $primaryColor;
            outline: none;
          }
        }

        .passwordToggle {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          cursor: pointer;
          color: #999;
        }
      }

      .submitButton {
        padding: 12px 30px;
        font-size: 16px;
        border: none;
        border-radius: 25px;
        color: #fff;
        background-color: $successColor;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: $secondaryColor;
        }
      }
    }

    .notification {
      padding: 10px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;

      &.success {
        background-color: $successColor;
        color: #fff;
      }

      &.error {
        background-color: $errorColor;
        color: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    .homeContainer {
      padding: 15px;
    }

    .accountForm {
      .formGroup {
        margin-bottom: 10px;
      }

      .submitButton {
        padding: 10px 25px;
        font-size: 14px;
      }
    }
  }
}
 // Media Query for screens smaller than 768px
 @media (max-width: 768px) {
  .homeContainer {
    padding: 15px;
  }

  .accountForm {
    .formGroup {
      margin-bottom: 10px;
    }

    .submitButton {
      padding: 10px 25px;
      font-size: 14px;
    }
  }
}
